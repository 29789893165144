<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0">Inventory</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- tabs -->

    <!-- Table Container Card -->
    <b-card no-body>
      <b-tabs class="m-2" lazy>
        <b-tab active>
          <template #title>
            <div>
              <b-img
                :src="require('@/assets/images/svg/egg.svg')"
                style="width: 25px"
              />
              <span>Egg Stock</span>
            </div>
          </template>
          <EggStockList />
        </b-tab>
        <b-tab lazy>
         <template #title>
            <div>
              <b-img
                :src="require('@/assets/images/svg/vaccination.svg')"
                style="margin-right: 7px; width: 20px"
              />
              <span>Vaccine</span>
            </div>
          </template>
          <vaccine-list />
        </b-tab>
        <b-tab lazy>
         <template #title>
            <div>
              <b-img
                :src="require('@/assets/images/svg/antibacterial.svg')"
                style="margin-right: 7px; width: 20px"
              />
              <span>Medicine/Disinfectant</span>
            </div>
          </template>

          <medicine-list />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BFormDatepicker,
  BTabs,
  BTab,
  BCardText,
  BImg
} from "bootstrap-vue";
import vSelect from "vue-select";
import EggStockList from "./EggStockList.vue";
import VaccineList from "./VaccineList.vue";
import MedicineList from "./MedicineList.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BFormDatepicker,
    vSelect,
    BCardText,
    BTabs,
    BTab,
    EggStockList,
    VaccineList,
    MedicineList,
	 BImg
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
