<template>
	<div>
		<!-- Table Container Card -->
		<b-card no-body>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Date picker -->
					<b-col cols="12" md="6">
						<b-row>
							<b-col md="4 p-0 mr-1">
								<label for="example-input">From Date</label>
								<b-form-datepicker
									id="from-date"
									placeholder="Choose a date"
									v-model="search_filter.from_date"
								/>
							</b-col>
							<b-col md="4 p-0">
								<label for="example-input">To Date</label>
								<b-form-datepicker
									id="to-date"
									placeholder="Choose a date"
									v-model="search_filter.to_date"
								/>
							</b-col>
							<b-col md="2 d-flex align-items-end">
								<b-button @click="getData()" variant="success ">
									Filter
								</b-button>
							</b-col>
						</b-row>
					</b-col>
					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="mt-2 d-flex justify-content-end align-items-center"
					>
						<div>
							<b-form-input
								type="text"
								class="form-control"
								placeholder="Search"
								@input="getData()"
								v-model.trim="search_filter.input_field"
								style="border-radius: 4px"
							/>
						</div>
						<b-button
							@click="clearFilter"
							variant="secondary
 			 ml-1"
						>
							Clear
						</b-button>
						<b-button @click="excelDownload()" variant="warning" class="ml-1">
							<feather-icon icon="DownloadIcon" size="16" />
						</b-button>
					</b-col>
				</b-row>
			</div>
			<div v-if="medicineInventory && medicineInventory.total > 0">
				<b-table
					id="medicineStock"
					:items="medicineInventory.data"
					responsive
					:fields="fields"
					show-empty
					empty-text="No matching records found"
					class="position-relative"
				>
					<template #head(invoiceStatus)>
						<feather-icon icon="TrendingUpIcon" class="mx-auto" />
					</template>

					<template #cell(index)="data">
						<div class="ml-1">
							{{ medicineInventory.from + data.index }}
						</div>
					</template>

					<!-- Column: Actions -->
					<template #cell(actions)="data">
						<div class="text-nowrap">
							<feather-icon
								:id="`invoice-row-${data.item.id}-preview-icon`"
								icon="EyeIcon"
								size="16"
								class="mx-1"
								@click="
									$router.push({
										name: 'apps-invoice-preview',
										params: { id: data.item.id },
									})
								"
							/>
							<b-tooltip
								title="Preview Invoice"
								:target="`invoice-row-${data.item.id}-preview-icon`"
							/>

							<!-- Dropdown -->
							<b-dropdown
								variant="link"
								toggle-class="p-0"
								no-caret
								:right="$store.state.appConfig.isRTL"
							>
								<template #button-content>
									<feather-icon
										icon="MoreVerticalIcon"
										size="16"
										class="align-middle text-body"
									/>
								</template>
								<b-dropdown-item>
									<feather-icon icon="DownloadIcon" />
									<span class="align-middle ml-50">Download</span>
								</b-dropdown-item>
								<b-dropdown-item
									:to="{
										name: 'apps-invoice-edit',
										params: { id: data.item.id },
									}"
								>
									<feather-icon icon="EditIcon" />
									<span class="align-middle ml-50">Edit</span>
								</b-dropdown-item>
							</b-dropdown>
						</div>
					</template>
				</b-table>
				<div class="d-flex justify-content-end mb-3 mt-2 mr-2">
					<b-button variant="primary">
						Total Stock : {{ this.medicine_stock ? this.medicine_stock : 0 }}
					</b-button>
				</div>
				<div>
					<b-pagination
						:value="medicineInventory.current_page"
						:total-rows="medicineInventory.total"
						:per-page="medicineInventory.per_page"
						align="right"
						@change="getData"
					></b-pagination>
				</div>
			</div>
			<div v-else class="d-flex flex-column align-items-center py-5">
				<b-img
					:src="
						require('@/assets/images/svg/infographics/purchase_medicine_infographic.svg')
					"
					class="mr-2"
				/>
				<h4 class="mt-2 Secondary">Medicine stock is Empty</h4>
			</div>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BImg,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BFormDatepicker,
	BTabs,
	BTab,
	BCardText,
} from "bootstrap-vue";

import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";

export default {
	components: {
		BCard,
		BImg,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BTooltip,
		BFormDatepicker,
		vSelect,
		BCardText,
		BTabs,
		BTab,
	},
	created: function () {
		this.getData();
		this.getMedicineCount();
	},
	data() {
		return {
			perPage: 5,
			currentPage: 1,
			searchQuery: null,
			fields: [
				{ key: "index", label: "S.no" },
				{ key: "disinfectant_name", label: "medicine name" },
				"source",
				{
					key: "purchase_date",
					label: "purchase date",
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
				{
					key: "expiry_date",
					label: "expiry date",
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
				// {
				// 	key: "damaged",
				// 	label: "damaged",
				// 	formatter: (value) => {
				// 		return value ? value : 0;
				// 	},
				// },
				"total_stock",
			],
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
			medicineInventory: {},
			medicine_stock: [],
		};
	},
	methods: {
		moment() {
			return moment();
		},
		getData(pageNo = 1) {
			let hatcheryId = this.$route.params.hatcheryId;
			let url = `web/hatchery/${hatcheryId}/get-medicine-stock`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.medicineInventory = response.data.medicineInventory;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		getMedicineCount() {
			let hatcheryId = this.$route.params.hatcheryId;
			let url = `web/hatcheries/${hatcheryId}/get-medicine-count`;
			axiosIns
				.get(url)
				.then((response) => {
					this.medicine_stock = response.data.medicine_stock;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(
					this.search_filter.from_date
				).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format(
					"YYYY-MM-DD"
				);
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-medicine-stock`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getData();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getData();
				});
		},

		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getData();
		},
	},
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
